@import "rsuite/styles/index.less";
@font-face {
  font-family: "Futura LT Regular";
  font-style: normal;
  font-weight: normal;
  src: url("fonts/FuturaLT.woff") format("woff");
}

.App {
  font-family: "Futura LT Regular";
  min-height: 100vh;
  user-select: none;
}

.PageContentUpper {
  background: linear-gradient(-60deg, #8a4c00, #002c59);
  background-size: 125% 125%;
  animation: gradient 2s ease infinite;
  min-height: 90.7vh;
  max-width: 100vw;
  color: white;
  text-align: center;
  @media (max-width: 900px) {
    padding-bottom: 30%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.ContentMain {
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  padding-bottom: 15%;
  justify-content: center;
  align-items: center;
}

.ContentSec {
  padding-top: 2%;
}

hr {
  border: 1.5px solid #ff9c1c;
  border-radius: 5%;
}

.PageContentLower {
  color: #001122;
  background-color: #ff9c1c;
  border-radius: 5%;
  text-align: center;
  position: relative;
  bottom: 0;
}

.NavBar {
  display: flex;
  min-width: 95vw;
  min-height: 7.5vh;
  justify-content: space-around;
  background-color: #ff9c1c;
  position: sticky;
  z-index: 1;
  top: 0;
}

.NavBarMob {
  display: flex;
  min-width: 95vw;
  min-height: 7.5vh;
  justify-content: space-around;
  background-color: #ff9c1c;
  position: sticky;
  z-index: 1;
  top: 0;
}

.NavButton {
  text-align: center;
  width: 16.5vw;
  height: 100%;
  color: black;
  font-size: 1.75vw;
  background: #ff9c1c;
}

.NavButton:hover {
  color: #001122;
}

.NavButton:focus {
  color: #001122;
}

.Dropdown {
  color: black;
  background-color: #ff9c1c;
}

.custom-shape-divider-bottom-1634721173 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1634721173 svg {
  position: relative;
  bottom: 0;
  width: calc(100% + 1.3px);
  height: 200px;
}

.custom-shape-divider-bottom-1634721173 .shape-fill {
  fill: #ff9c1c;
}

.custom-timeline {
  margin-left: 0px;
  position: relative;
  top: 0;
}

.custom-timeline .rs-timeline-item-custom-dot .rs-icon {
  background: #fff;
  position: absolute;
  top: 0;
  left: -2px;
  border: 2px solid #ddd;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 10px;
  padding-top: 0px;
  color: #999;
  margin-left: -13px;
}

.custom-timeline .rs-timeline-item-content {
  margin-left: 40px;
}

.custom-timeline .rs-timeline-item-time {
  margin-right: 0px;
}

.TimelineComponent {
  height: 10%;
  width: 75%;
  margin-bottom: 5%;
}
.TimelineComponentNoSize {
  margin-bottom: 5%;
}

.Panel {
  background-color: #ff9c1c;
  color: black;
  min-width: 95vw;
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;
}

ul {
  padding-right: 3.5%;
}

.ContactDetails {
  margin-top: 5%;
  display: flex;
  text-align: center;
  @media (max-width: 900px) {
    flex-direction: column;
  }
}

.PanelContact {
  background-color: #ff9c1c;
  color: black;
  margin-right: 2%;
  border: 2px solid #ff9c1c;
  border-radius: 5%;
  @media (max-width: 900px) {
    margin-top: 2%;
  }

  .ResearchPanels {
    min-width: 60vw;
    display: flex;

    @media (min-width: 900px) {
    }
  }
}
